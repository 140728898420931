<template>
  <div ref="drag" class="drag" :style="style">
    <div class="title"  @mousedown.stop="mousedown">
      答题结果
      <i class="el-icon-close" @click="$emit('close')"></i>
    </div>
    <div class="content">
      <p>【{{item.content && item.content.type | getType}}】{{item.content && item.content.name}}</p>
      <div class="option">
        <div v-for="(o,index) in options" :key="o" :class="{active: selected.includes(answer[index])}">
          <span :class="['opt', {
            err: item.result && !item.result.answer.includes(answer[index]) && myAnswer.includes(answer[index]),
            suc: item.result && item.result.answer.includes(answer[index]),
          }]">
            {{answer[index]}}. {{o}}
            <i v-if="item.result && !item.result.answer.includes(answer[index]) && myAnswer.includes(answer[index])" class="el-icon-error"></i>
            <i v-if="item.result && item.result.answer.includes(answer[index])" class="el-icon-success"></i>
          </span>
          <span>{{item.result && item.result.singleResult[index]}}人</span>
        </div>
      </div>
      <el-button type="primary" size="medium" round @click="$emit('close')">我知道了</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Result",
  filters: {
    getType(val) {
      switch(val) {
        case 'C': return '多选';
        case 'R': return '单选';
        case 'S': return '评分';
        case 'V': return '投票';
      }
    }
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      style: {},
      selected: [],
      answer: 'ABCDEFGHIJKMNOPQRSTUVWXYZ',
      myAnswer: '',
    }
  },
  computed: {
    options() {
      return Object.keys(this.item?.content || {}).filter(key => key.includes('option') && !!this.item?.content?.[key]).map(key => this.item?.content?.[key]);
    }
  },
  mounted() {
    this.myAnswer = this.$store.state.question[this.item?.questionId] || '';
    this.$nextTick(() => {
      this.$nextTick(() => {
        const el = this.$refs.drag
        this.style = {
          marginLeft: `${-el.clientWidth/2}px`,
          marginTop: `${-el.clientHeight/2}px`,
          left: '50%',
          top: '50%',
        }
      })
    })
  },
  methods: {
    mousedown(event) {
      const { top, left, width, height } = this.$refs.drag.getBoundingClientRect();
      const [distanceX, distanceY] = [event.clientX - (left + (width / 2)), event.clientY - (top + (height / 2))];
      document.onmousemove = (ev) => {
        var oevent = ev || event
        this.style = {
          ...this.style,
          left: `${oevent.clientX - distanceX}px`,
          top: `${oevent.clientY - distanceY}px`,
        }
      }
      document.onmouseup = () =>  {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.drag{
  position: fixed;
  top: 50vh;
  left: 50vw;
  width: 500px;
  // height: 100px;
  background: #fff;
  z-index: 55;
  box-shadow: rgba(0,0,0,.22) 0px 0px 8px 0px;
  overflow: hidden;
  border-radius: 4px;
  .title{
    height: 50px;
    position: relative;
    cursor: move;
    padding: 0 16px;
    background: #fff;
    line-height: 50px;
    border-bottom: 1px solid #999;
  }
  .content{
    padding: 10px;
    padding-bottom: 20px;
    .option{
      & > div{
        padding: 10px 0;
        .opt{
          display: inline-block;
          padding: 5px 10px;
          border-radius: 30px;
          margin-right: 10px;
          color: #546e7a;
          background-color: #edf1f7;
          padding-right: 26px;
          position: relative;
          .el-icon-error, .el-icon-success{
            position: absolute;
            right: 5px;
            top: 8px;
          }
          &.suc{
            color: #67C23A;
            background-color: #e1f3d8;
          }
          &.err{
            color: #F56C6C;
            background-color: #fde2e2;
          }
        }
      }
    }
  }
}
.el-button{
  display: block;
  margin: 0 auto;
}
.el-icon-close{
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 16px;
}
</style>