<template>
  <div class="char-room">
    <div id="wrap"></div>
    <el-dialog title="签到" class="player-signed" :visible.sync="signInVisible" :close-on-press-escape="false"	:close-on-click-modal="false" :show-close="false" width="442px" center>
      <template v-if="signInData">
        <CountDown :count="signInData.data.limitTime" @onStop="signInVisible = false"/>
        <span>{{ signInData.data.message }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="toSignIn">签到</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="公告" class="announcement-wrap" :visible.sync="bulletinVisible" :close-on-press-escape="false"	:close-on-click-modal="false" :show-close="false" width="510px" center>
      <template v-if="bulletinData">
        <span>{{ bulletinData.content }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="bulletinVisible = false">知道了</el-button>
        </span>
      </template>
    </el-dialog>
    <Drag v-if="showDrag" :item="questionData" @submit="questionConfirm" @close="showDrag = false;questionData = {}" />
    <Result v-if="showResult" :item="questionResult" @close="showResult = false;questionResult = {}" />
  </div>
</template>
<script>
import { createSign } from '@/api/api'

import { bGetToken } from '@/api/api'
import CountDown from './CountDown'
import Drag from './Drag'
import Result from './Result'
export default {
  name: 'LivePlayer',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      cssSrc: "https://player.polyv.net/jssdk/polyv-chatroom.min.css",
      vodPlayerJs: 'https://player.polyv.net/jssdk/polyv-chatroom.min.js',
      chatroom: null,
      signInVisible: false,
      // signInData: {EVENT: "STOP_SIGN_IN",checkinId: "f9e28f10-1f63-11eb-9ead-057ac9",data: {roomId: "1940752", timestamp: 1604579988826, sign: "0EA7AE7F096FB63481BFB69A22F88439", socketMsgId: "16045799888282720226"},roomId: "1940752",signNum: 0,total: 2},
      signInData: null,
      bulletinVisible: false,
      questionList: [], // 多选
      questionSin: null,// 单选
      showDrag: false,
      bulletinData: null,
      questionData: {},
      showResult: false,
      questionResult: {},
    }
  },
  mounted() {
    // this.loadPlayerCSS();
    this.loadPlayerScript(this.getRoomObj);
    // this.$modal.show('qcard',{
    //   draggable:true
    // })
  },
  destroyed() {
    if (this.chatroom) {
      // this.chatroom.destroy();
    }
  },
  components: {
    CountDown,
    Drag,
    Result
  },
  methods: {
    questionConfirm(option) {
      let that = this
      let userInfo = this.$store.state.userInfo
      let socket = this.chatroom.chat.socket
      this.$store.dispatch('setQuestion', {
        key: this.questionData.questionId,
        value: option,
      })
      socket.emit('message', JSON.stringify({
        EVENT: 'ANSWER_TEST_QUESTION',
        roomId: that.chatroom.channelId,
        nick: this.detail.buyUserName,
        userId: userInfo.phone,
        option,
        questionId: this.questionData.questionId
      }));
      this.questionList = []
      this.showDrag = false
    },
    sendAns() {
      let socket = this.chatroom.chat.socket
      //ANSWER_TEST_QUESTION
    },
    toSignIn() {
      let that = this
      let userInfo = this.$store.state.userInfo
      let socket = this.chatroom.chat.socket
      socket.emit('message', JSON.stringify({
        EVENT:'TO_SIGN_IN',
        roomId: that.signInData.roomId,
        checkinId: that.signInData.data.checkinId,
        user: {
          nick: userInfo.name || `pc-${userInfo.name}`,
          userId: userInfo.phone
        }
      }));
      this.signInVisible = false
      this.signInData = null
    },
    async getRoomObj() {
      const appId = 'fjzfeuje1x'
      let userInfo = this.$store.state.userInfo
			const timestamp = new Date().getTime()
		
      let signResponse = await createSign([
        {key: 'appId', val: appId},
        {key: 'channelId', val: this.detail.liveChannelNumber},
        {key: 'timestamp', val: timestamp},
        {key: 'userId', val: userInfo.phone},
        {key: 'role', val: 'viewer'}
      ])
     
      if(+signResponse.returnCode !== 10001) {
        this.$message.error(signResponse.returnMsg)
        return false
      }
      let params = {
        appId: appId,
        timestamp: timestamp,
        sign: signResponse.data.sign,
        channelId: this.detail.liveChannelNumber,
        userId: userInfo.phone,
        role: 'viewer',
      }
      let response = await bGetToken(params)
      if(response.code !== 200) {
        return false
      }
      this.loadCharRoom(response.data)
    },
    loadPlayerScript(callback) {
      // if (!window.polyvPlayer) {
      //   const myScript = document.createElement('script');
      //   myScript.setAttribute('src', this.vodPlayerJs);
      //   myScript.onload = callback;
      //   document.body.appendChild(myScript);
      // } else {
      //   callback();
      // }
      callback();
    },
    // loadPlayerCSS() {
    //   const myCss = document.createElement('link');
    //   myCss.setAttribute('ref', 'stylesheet');
    //   myCss.setAttribute('href', this.cssSrc);
    //   var head = document.getElementsByTagName('head')[0]; 
    //   head.appendChild(myCss);
    // },
    // http://dev.polyv.net/2019/liveproduct/l-api/zbglgn/pdcz/get-chat-token/
    loadCharRoom(obj) {
      let userInfo = this.$store.state.userInfo
      let that = this
      console.log(obj);
      this.chatroom = new PolyvChatRoom({
        roomId: this.detail.liveChannelNumber,
        userId: userInfo.phone,
        nick: this.detail.buyUserName,
        pic: userInfo.headImg,
        token: obj.token,
        mediaChannelKey: obj.mediaChannelKey,
        version: '4.3',
        container: '#wrap',
        width: 300,
        height: 600,
        userType: 'student',
        showUserList:false,
        roomMessage: function(data) {
          // TODO
          // data为聊天室socket消息，当有聊天室消息时会触发此方法
          that.signInVisible = false;
          // that.bulletinVisible = false;
          that.showDrag = false;
          // that.showResult = false;
          if(data.EVENT === "SIGN_IN") {
            that.signInData = data
            that.signInVisible = true
          }
          if(data.EVENT === "STOP_SIGN_IN") {
            that.signInData = null
            that.signInVisible = false
          }
          if(data.EVENT === 'BULLETIN') {
            that.bulletinVisible = true
            
            that.bulletinData = data
          }
          // 开始抽奖
          if(data.EVENT === 'LotteryStart') {

          }
          // 结束抽奖
          if(data.EVENT === 'LotteryEnd') {

          }
          // 答题卡
          if(data.EVENT === 'GET_TEST_QUESTION_CONTENT') {
            that.showDrag = true
            
            that.questionData = data
            // itemType 答题类型，1表示快速问答，0表示答题卡
          }
          if(data.EVENT === 'STOP_TEST_QUESTION') {
            that.showDrag = false
            that.questionData = {}
            // 结束答题
          }
          if(data.EVENT === 'GET_TEST_QUESTION_RESULT') {
            that.showResult = true
            that.questionResult = data
            // 答题结果
          }
          console.log(data, 'data');
        }
      });
      this.$emit('charRoomInit', this.chatroom)
    },
  }
}
</script>

<style lang="less" scoped>
.char-room{
  position: relative;
  .qcard{
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: -200px;
    background: #fff;
    z-index: 30;
    border-radius: 5px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border: 1px solid rgb(197, 197, 197);
    .qtitle{
      font-size: 16px;
      text-align: center;
    }
    .qbody{

    }
  }
}
.messagedialog{
  background: none!important;
}
.el-dialog__title{
  display: block;
  width: 100%;
  height: 42px;
  line-height: 42px;
  background-color: #f6f7fb;
  border-bottom: 1px solid #eceff0;
  border-radius: 6px 6px 0 0;
}
.player-signed .el-dialog__body{

}
.player-signed .el-dialog__body span{
  text-align: center;
  display: block;
}
.drag-btn{
  display: flex;
  justify-content: center;
}
.drag-options{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 200px;
  .dop-item{
    width: 40px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #999;
    text-align: center;
    margin: 10px;
  }
  .active{
    background: blue;
    color: #fff;
  }
}
</style>