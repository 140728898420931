<template>
  <div ref="drag" class="drag" :style="style">
    <div class="title"  @mousedown.stop="mousedown">
      <template v-if="item.itemType === 1">
        快速问答【{{item.type | getType}}】
      </template>
      <template v-else>
        答题卡
      </template>
      <i class="el-icon-close" @click="$emit('close')"></i>
    </div>
    <div class="content">
      <div class="option" v-if="item.itemType === 1">
        <span v-for="(o,index) in options" :key="o" @click="select(answer[index])" :class="{active: selected.includes(answer[index])}">{{o}}</span>
      </div>
      <template v-else>
        <p>【{{item.type | getType}}】{{item.name}}</p>
        <el-checkbox-group v-if="item.type === 'C'" v-model="selected" size="mini">
          <el-checkbox v-for="(o,index) in options" :key="o" :label="answer[index]" border>{{o}}<span v-if="!!item[`tips${index + 1}`]">（{{item[`tips${index + 1}`]}}）</span></el-checkbox>
        </el-checkbox-group>
        <el-radio-group v-else v-model="selected" size="mini">
          <el-radio v-for="(o,index) in options" :key="o" :label="answer[index]" border>{{o}}<span v-if="!!item[`tips${index + 1}`]">（{{item[`tips${index + 1}`]}}）</span></el-radio>
        </el-radio-group>
      </template>
      <el-button type="primary" size="medium" round @click="submit">提 交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drag",
  filters: {
    getType(val) {
      switch(val) {
        case 'C': return '多选';
        case 'R': return '单选';
        case 'S': return '评分';
        case 'V': return '投票';
      }
    }
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      id: new Date().getTime(),
      style: {},
      selected: [],
      answer: 'ABCDEFGHIJKMNOPQRSTUVWXYZ'
    }
  },
  computed: {
    options() {
      return Object.keys(this.item).filter(key => key.includes('option') && !!this.item[key]).map(key => this.item[key]);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const video = document.getElementById('player');
      let lt = {};
      if (video && this.item.itemType === 1) {
        this.style = {
          width: '200px'
        }
      }
      this.$nextTick(() => {
        const el = this.$refs.drag
        if (video && this.item.itemType === 1) {
          const { bottom, right } = video.getBoundingClientRect();
          lt = this.item.itemType === 1 ? {
            left: `${right - (el.clientWidth/2)}px`,
            top: `${bottom - (el.clientHeight/2)}px`,
            width: '200px',
          } : {
            left: '50%',
            top: '50%',
          }
        } else {
          lt = {
            left: '50%',
            top: '50%',
          }
        }
        this.style = {
          marginLeft: `${-el.clientWidth/2}px`,
          marginTop: `${-el.clientHeight/2}px`,
          ...lt
        }
      })
    })
  },
  methods: {
    select(val) {
      if (this.item.type === 'R') return this.selected = [val];
      if (this.selected.includes(val)) {
        this.selected.splice(this.selected.findIndex(i => i === val));
      } else {
        this.selected.push(val)
      }
    },
    submit() {
      this.$emit('submit', Array.isArray(this.selected) ? this.selected.join('') : this.selected);
    },
    mousedown(event) {
      const { top, left, width, height } = this.$refs.drag.getBoundingClientRect();
      const [distanceX, distanceY] = [event.clientX - (left + (width / 2)), event.clientY - (top + (height / 2))];
      document.onmousemove = (ev) => {
        var oevent = ev || event
        this.style = {
          ...this.style,
          left: `${oevent.clientX - distanceX}px`,
          top: `${oevent.clientY - distanceY}px`,
        }
      }
      document.onmouseup = () =>  {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.drag{
  position: fixed;
  top: 50vh;
  left: 50vw;
  width: 500px;
  // height: 100px;
  background: #fff;
  z-index: 55;
  box-shadow: rgba(0,0,0,.22) 0px 0px 8px 0px;
  overflow: hidden;
  border-radius: 4px;
  .title{
    height: 50px;
    position: relative;
    cursor: move;
    padding: 0 16px;
    background: #fff;
    line-height: 50px;
    border-bottom: 1px solid #999;
  }
  .content{
    padding: 10px;
    padding-bottom: 20px;
    .option{
      text-align: center;
      & > span{
        display: inline-block;
        border: 1px solid #cfd8dc;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        text-align: center;
        line-height: 38px;
        background-color: #fff;
        font-size: 20px;
        margin: 10px;
        cursor: pointer;
        &.active{
          border: 1px solid #FF3F47;
          color: #FF3F47;
        }
      }
    }
    p{
      text-align: left;
    }
  }
}
.el-radio-group, .el-checkbox-group{
  display: block;
  margin: 20px 0;
  .el-radio, .el-checkbox{
    display: block;
    margin: 10px 0;
  }
  .el-radio.is-bordered+.el-radio.is-bordered{
    margin-left: 0;
  }
}
.el-checkbox-group{
  display: block;
  margin: 20px 0;
  .el-checkbox{
    display: block;
    margin: 10px 0;
  }
  .el-checkbox.is-bordered+.el-checkbox.is-bordered{
    margin-left: 0;
  }
}
.el-button{
  display: block;
  margin: 0 auto;
}
.el-icon-close{
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 16px;
}
</style>