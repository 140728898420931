<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2021-05-24 13:40:48
 * @LastEditors  : XiaoLei
-->
<template>
  <div class="video">
    <div id="player"></div>
    <div class="class-list" v-if="courseGroupDetail">
      <div class="teacher-info" v-if="teacher">
        <div class="avatar">
          <img :src="teacher.headPortrait" :alt="teacher.teacherName">
        </div>
        <div class="intro">
          <p class="t-title">当前主讲老师：{{teacher.teacherName}}</p>
          <p class="t-sub-title">{{teacher.content}}</p>
        </div>
      </div>
      <!-- 目录 -->
      <!-- <div class="ml">
        <p>目录</p>
      </div> -->
      <div class="scroll">
        <!-- 章节 -->
        <template v-for="(item, index) of courseGroupDetail.chapterWebVos">
          <div :key="index">
            <div class="zj-bt" @click="toggleClass(index)" v-if="courseGroupDetail.hasChapter">
              <p class="name">{{item.chapterName}}</p>
              <p class="arrow" :class="{'turnArrow' : listType[index]}"></p>
            </div>
            <template v-if="courseGroupDetail.hasChapter">
              <div v-if="listType[index]">
                <div class="zj" :class="{'now-play':citem.id === classId}" v-for="(citem, cindex) of item.courseWebVos" :key="cindex" @click="classClick(citem)">
                  <div class="zj-left">
                    <p class="play-icon"></p>
                    <div class="zj-content">
                      <p class="zj-title">{{citem.courseName}}</p>
                      <p class="zj-sub-title">{{citem.classTime}}</p>
                    </div>
                  </div>
                  <p v-if="citem.id !== classId" class="replay" :class="{'color-red': +citem.liveStatus === 2}">{{citem.liveStatus | liveStateFilter}}</p>
                  <p v-if="citem.id === classId" class="replay play-now" ref="now">当前播放</p>
                </div>
              </div>
            </template>
            <template v-if="!courseGroupDetail.hasChapter">
              <div class="zj" v-for="(citem, cindex) of item.courseWebVos" :key="cindex" @click="classClick(citem)">
                <div class="zj-left">
                  <p class="play-icon"></p>
                  <div class="zj-content">
                    <p class="zj-title">{{citem.courseName}}</p>
                    <p class="zj-sub-title">{{citem.classTime}}</p>
                  </div>
                </div>
                <p v-if="citem.id !== classId" class="replay" :class="{'color-red': +citem.liveStatus === 2}">{{citem.liveStatus | liveStateFilter}}</p>
                  <p v-if="citem.id === classId" class="replay play-now" ref="now">当前播放</p>
              </div>
            </template>
            
          </div>
        </template>
        
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import { LIVE_STATUS } from '@/config/config'
export default {
  name: "VideoPlayer",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    courseGroupDetail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return {
      vodPlayerJs: 'https://player.polyv.net/script/player.js',
      vid:'88083abbf5bcf1356e05d39666be527a_8',
      listType: [true],
      classId: null,
      teacher: null,
    }
  },
  mounted() {
    this.classId = this.$route.params.id
    this.vid = this.detail.vid
    this.loadPlayerScript(this.loadPlayer);
    this.$nextTick(() => {
      this.setMlOpen()
    })
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
  methods: {
    setMlOpen() {
      let listType = []
      let teacherId = null
      this.courseGroupDetail.chapterWebVos.forEach((courseitem, index)=> {
        courseitem.courseWebVos.forEach((item) => {
          if(item.id === this.detail.id) {
            listType[index] = true
            teacherId = item.teacherId
          }
        })
      });
      this.courseGroupDetail.teachers.forEach((item) => {
        if(item.id === teacherId) {
          this.teacher = item
        }
      })
      this.listType = listType
      this.$nextTick(() => {
        let el = this.$refs.now[0] || this.$refs.now;
        el.scrollIntoView({
          block: 'center'
        })
      });
    },
    toggleClass(index) {
      // this.listType[index] = !this.listType[index]
      Vue.set(this.listType, index, !this.listType[index])
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script');
        myScript.setAttribute('src', this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: '#player',
        width: 944,
        height: 636,
        vid: this.vid ,
      });
    },
    classClick(item) {
      if(item.liveStatus === 2 || item.liveStatus === 3 || item.liveStatus === 5) {
        this.$router.push({path: `/course/class/${item.id}?id=${item.courseGroupId}`})
        window.location.reload()
        return false
      }
    },
  },
  filters: {
    liveStateFilter(val) {
      return LIVE_STATUS[+val] || '' 
    }
  }
}
</script>

<style lang="less" scoped>
.video{
  display: flex;
  .class-list{
    width: 328px;
    height: 636px;
    background: #2D2D2D;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 0px 6px 6px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .scroll{
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
  }
  .teacher-info{
    width: 328px;
    min-height: 92px;
    background: #333333;
    padding: 16px 20px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    .avatar{
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      background: #eee;
      margin-right: 20px;
      flex-grow: 0;
      flex-shrink: 0;
      img{
        width: 100%;
      }
    }
    .intro{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 21px 0 5px;
      .t-title{
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
      }
      .t-sub-title{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
    }
  }
  .ml{
    height: 57px;
    background: #2D2D2D;
    border-bottom: 1px solid #191919;
    padding-left: 20px;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    p{
      height: 21px;
      line-height: 21px;
      border-left: 4px solid #FF3F47;
      padding-left: 11px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .zj-bt{
    height: 55px;
    border-bottom: 1px solid #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 10px;
    cursor: pointer;
    .name{
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 16px;
      border-left: 4px solid #FF3F47;
      padding-left: 10px;
    }
    .arrow{
      width: 14px;
      height: 8px;
      background: url('../../assets/images/icon/arrow-white.png') no-repeat;
      background-size: contain;
      transition: all 0.3s;
    }
  }
  .zj{
    height: 55px;
    border-bottom: 1px solid #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 17px;
    cursor: pointer;
    .play-icon{
      width: 17px;
      height: 17px;
      background: url("../../assets/images/icon/replay.png") no-repeat;
      background-size: contain;
      margin-right: 18px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .zj-left{
      display: flex;
      align-items: center;
    }
    .zj-content{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 40px;
      .zj-title{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
      .zj-sub-title{
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 12px;
      }
    }
    .replay{
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 12px;
      flex-grow: 0;
      flex-shrink: 0;
      transform: translateY(10px);
      
    }
  }
  .zj-actived{
    background: #191919;
  }
}
.color-red{
  color: #FF3F47!important;
}
.turnArrow{
  transform: rotate(180deg);
}
.now-play{
  background: #191919;
}
.play-now{
  color: #2E8DF4!important;
}
</style>